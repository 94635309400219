<script setup>
import { ref, defineAsyncComponent } from 'vue'
import { useAuthStore } from '@/store/auth'

const daftarpembelian = defineAsyncComponent(() =>
  import('@/components/laporan/pembelian/daftarpembelian.vue')
)

const detailpembelian = defineAsyncComponent(() =>
  import('@/components/laporan/pembelian/detailpembelian.vue')
)

const pembeliansupplier = defineAsyncComponent(() =>
  import('@/components/laporan/pembelian/pembeliansupplier.vue')
)

const detailpembeliansupplier = defineAsyncComponent(() =>
  import('@/components/laporan/pembelian/detailpembeliansupplier.vue')
)

const detailpembeliangolongan = defineAsyncComponent(() =>
  import('@/components/laporan/pembelian/detailpembeliangolongan.vue')
)

const daftarPembelianBarang = defineAsyncComponent(() =>
  import('@/components/laporan/pembelian/DaftarPembelianBarang.vue')
)

const daftarPembelianPabrik = defineAsyncComponent(() =>
  import('@/components/laporan/pembelian/DaftarPembelianPabrik.vue')
)

const daftarPembelianBarangSupplier = defineAsyncComponent(() =>
  import('@/components/laporan/pembelian/DaftarPembelianBarangSupplier.vue')
)

const auth = useAuthStore()

let laporan = ref('daftarpembelian')
let hideSideBar = ref(false)

const items = ref([
  {
    label: 'Laporan Daftar Pembelian',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'daftarpembelian'
    },
    visible: auth.user.roles.some((a) => ['admin', 'manager', 'edp', 'sp_penagihan', 'fakturis_pembelian', 'pembelian'].includes(a)),
  },
  {
    label: 'Laporan Detail Pembelian',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'detailpembelian'
    },
    visible: auth.user.roles.some((a) => ['admin', 'manager', 'edp', 'pajak', 'sp_penagihan', 'fakturis_pembelian', 'pembelian'].includes(a)),
  },
  {
    label: 'Laporan Daftar Pembelian Per Supplier',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'pembeliansupplier'
    },
    visible: auth.user.roles.some((a) => ['admin', 'manager', 'edp', 'sp_penagihan', 'fakturis_pembelian', 'pembelian'].includes(a)),
  },
  {
    label: 'Laporan Detail Pembelian Per Supplier',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'detailpembeliansupplier'
    },
    visible: auth.user.roles.some((a) => ['admin', 'manager', 'edp', 'sp_penagihan', 'fakturis_pembelian', 'pembelian'].includes(a)),
  },
  {
    label: 'Laporan Detail Pembelian Per Golongan',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'detailpembeliangolongan'
    },
    visible: auth.user.roles.some((a) => ['admin', 'manager', 'edp', 'sp_penagihan', 'fakturis_pembelian', 'pembelian'].includes(a)),
  },
  {
    label: 'Laporan Daftar Pembelian Per Barang',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'daftarPembelianBarang'
    },
    visible: auth.user.roles.some((a) => ['admin', 'manager', 'edp', 'sp_penagihan', 'fakturis_pembelian', 'pembelian'].includes(a)),
  },
  {
    label: 'Laporan Daftar Pembelian Per Barang Per Supplier',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'daftarPembelianBarangSupplier'
    },
    visible: auth.user.roles.some((a) => ['admin', 'manager', 'edp', 'sp_penagihan', 'fakturis_pembelian', 'pembelian'].includes(a)),
  },
  {
    label: 'Laporan Daftar Pembelian Per Pabrik',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'daftarPembelianPabrik'
    },
    visible: auth.user.roles.some((a) => ['admin', 'manager', 'edp', 'sp_penagihan', 'fakturis_pembelian', 'pembelian'].includes(a)),
  },
])

function onToggle() {
  hideSideBar.value = !hideSideBar.value
}
</script>

<template>
  <div>
    <div class="mb-4 flex justify-content-between align-items-center">
      <span class="pr-2 mr-2 font-bold text-2xl">Laporan Daftar Pembelian</span>
      <div>
        <i class="pi pi-home mr-2"></i>
        <span class="font-small">/ Laporan / Daftar Pembelian</span>
      </div>
    </div>
    <div class="grid">
      <div
        class="col-12 md:col-3"
        :class="{
          hidden: hideSideBar,
          'transition-duration-500': hideSideBar,
        }"
      >
        <Menu :model="items" class="menu-laporan w-full border-none" />
      </div>
      <div
        class="card col-12 md:col-9"
        :class="{
          'md:col-12': hideSideBar,
          'transition-duration-200': hideSideBar,
        }"
      >
        <div>
          <daftarpembelian
            v-if="laporan === 'daftarpembelian'"
            @toggle="onToggle"
          />
          <detailpembelian
            v-if="laporan === 'detailpembelian'"
            @toggle="onToggle"
          />
          <pembeliansupplier
            v-if="laporan === 'pembeliansupplier'"
            @toggle="onToggle"
          />
          <detailpembeliansupplier
            v-if="laporan === 'detailpembeliansupplier'"
            @toggle="onToggle"
          />
          <detailpembeliangolongan
            v-if="laporan === 'detailpembeliangolongan'"
            @toggle="onToggle"
          />
          <daftarPembelianBarang
            v-if="laporan === 'daftarPembelianBarang'"
            @toggle="onToggle"
          />
          <daftarPembelianBarangSupplier
            v-if="laporan === 'daftarPembelianBarangSupplier'"
            @toggle="onToggle"
          />
          <daftarPembelianPabrik
            v-if="laporan === 'daftarPembelianPabrik'"
            @toggle="onToggle"
          />
        </div>
      </div>
    </div>
  </div>
</template>
