<script setup>
import { ref, defineAsyncComponent } from 'vue'
import { useAuthStore } from '@/store/auth'

const laporanPenjualanGolongan = defineAsyncComponent(() =>
  import('@/components/laporan/apoteker/PenjualanGolongan.vue')
)

const laporanReturGolonganPenjualan = defineAsyncComponent(() =>
  import('@/components/laporan/apoteker/ReturGolonganPenjualan.vue')
)

const laporanReturGolonganPembelian = defineAsyncComponent(() =>
  import('@/components/laporan/apoteker/ReturGolonganPembelian.vue')
)

const laporanReturPrekursor = defineAsyncComponent(() =>
  import('@/components/laporan/apoteker/ReturPrekursor.vue')
)

const laporanTriwulanBatch = defineAsyncComponent(() =>
  import('@/components/laporan/apoteker/TriwulanBatch.vue')
)

const laporanTriwulanBarang = defineAsyncComponent(() =>
  import('@/components/laporan/apoteker/TriwulanBarang.vue')
)

const laporanEReport = defineAsyncComponent(() =>
  import('@/components/laporan/apoteker/EReport.vue')
)

const laporanSiOdie = defineAsyncComponent(() =>
  import('@/components/laporan/apoteker/SiOdie.vue')
)

const laporanSiOdieTriwulan = defineAsyncComponent(() =>
  import('@/components/laporan/apoteker/SiOdieTriwulan.vue')
)

const auth = useAuthStore()

let laporan = ref('PenjualanGolongan')
let hideSideBar = ref(false)

const items = ref([
  {
    label: 'Laporan Penjualan Barang per Golongan Obat',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'PenjualanGolongan'
    },
    visible: auth.user.roles.some((a) => ['admin', 'manager', 'edp', 'apoteker'].includes(a)),
  },
  // {
  //   label: 'Laporan Retur Penjualan per Golongan Obat',
  //   icon: 'pi pi-arrow-circle-right',
  //   command: () => {
  //     laporan.value = 'ReturGolonganPenjualan'
  //   },
  //   visible: auth.user.roles.some((a) => ['admin', 'manager', 'edp', 'apoteker'].includes(a)),
  // },
  // {
  //   label: 'Laporan Retur Pembelian per Golongan Obat',
  //   icon: 'pi pi-arrow-circle-right',
  //   command: () => {
  //     laporan.value = 'ReturGolonganPembelian'
  //   },
  //   visible: auth.user.roles.some((a) => ['admin', 'manager', 'edp', 'apoteker'].includes(a)),
  // },
  {
    label: 'Laporan Retur E-Report Prekursor per Bulan',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'ReturPrekursor'
    },
    visible: auth.user.roles.some((a) => ['admin', 'manager', 'edp', 'apoteker'].includes(a)),
  },
  {
    label: 'Laporan E-Report Triwulan',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'TriwulanBarang'
    },
    visible: auth.user.roles.some((a) => ['admin', 'manager', 'edp', 'apoteker'].includes(a)),
  },
  {
    label: 'Laporan E-Report per Bulan',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'EReport'
    },
    visible: auth.user.roles.some((a) => ['admin', 'manager', 'edp', 'apoteker'].includes(a)),
  },
  {
    label: 'Laporan SI-ODIE per Bulan',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'SiOdie'
    },
    visible: auth.user.roles.some((a) => ['admin', 'manager', 'edp', 'apoteker'].includes(a)),
  },
  {
    label: 'Laporan SI-ODIE Triwulan',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'SiOdieTriwulan'
    },
    visible: auth.user.roles.some((a) => ['admin', 'manager', 'edp', 'apoteker'].includes(a)),
  },
])

function onToggle() {
  hideSideBar.value = !hideSideBar.value
}
</script>

<template>
  <div>
    <div class="mb-4 flex justify-content-between align-items-center">
      <span class="pr-2 mr-2 font-bold text-2xl">Laporan Apoteker</span>
      <div>
        <i class="pi pi-home mr-2"></i>
        <span class="font-small">/ Laporan / Apoteker</span>
      </div>
    </div>
    <div class="grid">
      <div
        class="col-12 md:col-3"
        :class="{
          hidden: hideSideBar,
          'transition-duration-500': hideSideBar,
        }"
      >
        <Menu :model="items" class="menu-laporan w-full border-none" />
      </div>
      <div
        class="card col-12 md:col-9"
        :class="{
          'md:col-12': hideSideBar,
          'transition-duration-200': hideSideBar,
        }"
      >
        <div>
          <laporanPenjualanGolongan
            v-if="laporan === 'PenjualanGolongan'"
            @toggle="onToggle"
          />
          <laporanReturGolonganPenjualan
            v-if="laporan === 'ReturGolonganPenjualan'"
            @toggle="onToggle"
          />
          <laporanReturGolonganPembelian
            v-if="laporan === 'ReturGolonganPembelian'"
            @toggle="onToggle"
          />
          <laporanReturPrekursor
            v-if="laporan === 'ReturPrekursor'"
            @toggle="onToggle"
          />
          <laporanTriwulanBatch
            v-if="laporan === 'TriwulanBatch'"
            @toggle="onToggle"
          />
          <laporanTriwulanBarang
            v-if="laporan === 'TriwulanBarang'"
            @toggle="onToggle"
          />
          <laporanEReport v-if="laporan === 'EReport'" @toggle="onToggle" />
          <laporanSiOdie v-if="laporan === 'SiOdie'" @toggle="onToggle" />
          <laporanSiOdieTriwulan
            v-if="laporan === 'SiOdieTriwulan'"
            @toggle="onToggle"
          />
        </div>
      </div>
    </div>
  </div>
</template>
