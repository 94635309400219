<script setup>
import { ref, defineAsyncComponent } from 'vue'
import { useAuthStore } from '@/store/auth'

const laporanKomisi = defineAsyncComponent(() =>
  import('@/components/laporan/salesman/Komisi.vue')
)

const laporanPenjualan = defineAsyncComponent(() =>
  import('@/components/laporan/salesman/Penjualan.vue')
)

const auth = useAuthStore()

let laporan = ref('komisi')
let hideSideBar = ref(false)

const items = ref([
  {
    label: 'Laporan Komisi Salesman',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'komisi'
    },
    visible: auth.user.roles.some((a) => ['admin', 'manager', 'edp', 'fakturis_penjualan', 'sp_penagihan'].includes(a)),
  },
  {
    label: 'Laporan Penjualan Salesman',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'penjualan'
    },
    visible: auth.user.roles.some((a) => ['admin', 'manager', 'edp', 'fakturis_penjualan', 'sp_penagihan'].includes(a)),
  },
])

function onToggle() {
  hideSideBar.value = !hideSideBar.value
}
</script>

<template>
  <div>
    <div class="mb-4 flex justify-content-between align-items-center">
      <span class="pr-2 mr-2 font-bold text-2xl">Laporan Salesman</span>
      <div>
        <i class="pi pi-home mr-2"></i>
        <span class="font-small">/ Laporan / Salesman</span>
      </div>
    </div>
    <div class="grid">
      <div
        class="col-12 md:col-3"
        :class="{
          hidden: hideSideBar,
          'transition-duration-500': hideSideBar,
        }"
      >
        <Menu :model="items" class="menu-laporan w-full border-none" />
      </div>
      <div
        class="card col-12 md:col-9"
        :class="{
          'md:col-12': hideSideBar,
          'transition-duration-200': hideSideBar,
        }"
      >
        <div>
          <laporanPenjualan v-if="laporan === 'penjualan'" @toggle="onToggle" />
          <laporanKomisi v-if="laporan === 'komisi'" @toggle="onToggle" />
        </div>
      </div>
    </div>
  </div>
</template>
