<script setup>
import { ref, defineAsyncComponent } from 'vue'
import { useAuthStore } from '@/store/auth'

const laporanDetail = defineAsyncComponent(() =>
  import('@/components/laporan/hutang/Detail.vue')
)

const laporanRangkuman = defineAsyncComponent(() =>
  import('@/components/laporan/hutang/Rangkuman.vue')
)

const laporanKartu = defineAsyncComponent(() =>
  import('@/components/laporan/hutang/Kartu.vue')
)

const laporanMutasi = defineAsyncComponent(() =>
  import('@/components/laporan/hutang/Mutasi.vue')
)

const laporanPembayaran = defineAsyncComponent(() =>
  import('@/components/laporan/hutang/Pembayaran.vue')
)

const auth = useAuthStore()

let laporan = ref('rangkuman')
let hideSideBar = ref(false)

const items = ref([
  {
    label: 'Laporan Hutang Usaha Rangkuman',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'rangkuman'
    },
    visible: auth.user.roles.some((a) => ['admin', 'manager', 'edp', 'pajak'].includes(a)),
  },
  {
    label: 'Laporan Saldo Hutang Detail',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'detail'
    },
    visible: auth.user.roles.some((a) => ['admin', 'manager', 'edp', 'pajak'].includes(a)),
  },
  {
    label: 'Laporan Kartu Hutang',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'kartu'
    },
    visible: auth.user.roles.some((a) => ['admin', 'manager', 'edp', 'pajak'].includes(a)),
  },
  {
    label: 'Laporan Mutasi Hutang',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'mutasi'
    },
    visible: auth.user.roles.some((a) => ['admin', 'manager', 'edp', 'pajak'].includes(a)),
  },
  {
    label: 'Laporan Pembayaran Hutang Usaha',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'pembayaran'
    },
    visible: auth.user.roles.some((a) => ['admin', 'manager', 'edp', 'pajak'].includes(a)),
  },
])

function onToggle() {
  hideSideBar.value = !hideSideBar.value
}
</script>

<template>
  <div>
    <div class="mb-4 flex justify-content-between align-items-center">
      <span class="pr-2 mr-2 font-bold text-2xl">Hutang Usaha</span>
      <div>
        <i class="pi pi-home mr-2"></i>
        <span class="font-small">/ Hutang / Usaha</span>
      </div>
    </div>
    <div class="grid">
      <div
        class="col-12 md:col-3"
        :class="{
          hidden: hideSideBar,
          'transition-duration-500': hideSideBar,
        }"
      >
        <Menu :model="items" class="menu-laporan w-full border-none" />
      </div>
      <div
        class="card col-12 md:col-9"
        :class="{
          'md:col-12': hideSideBar,
          'transition-duration-200': hideSideBar,
        }"
      >
        <div>
          <laporanDetail v-if="laporan === 'detail'" @toggle="onToggle" />
          <laporanRangkuman v-if="laporan === 'rangkuman'" @toggle="onToggle" />
          <laporanKartu v-if="laporan === 'kartu'" @toggle="onToggle" />
          <laporanMutasi v-if="laporan === 'mutasi'" @toggle="onToggle" />
          <laporanPembayaran
            v-if="laporan === 'pembayaran'"
            @toggle="onToggle"
          />
        </div>
      </div>
    </div>
  </div>
</template>
